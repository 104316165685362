// import '@fortawesome/fontawesome-free/js/fontawesome';
// import '@fortawesome/fontawesome-free/js/solid';
// import '@fortawesome/fontawesome-free/js/brands';

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import SplitType from 'split-type';
window.SplitType = SplitType;

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;

jQuery(document).ready(function($) {
	var windowWidth = $(window).width()

/* ====================== MOBILE NAV ======================  */
    $('.menu-toggle').on("click", function() {
        $(this).toggleClass('active');
        $('.mobile-nav').toggleClass('open');
        $('header').toggleClass('open');
        $('body').toggleClass('noscroll');
    })

    if(windowWidth < 1280) {
	    $('.main-nav .menu-item-has-children').on("click", function() {
	       $(this).toggleClass('open');
	       $(this).find('.sub-menu').slideToggle();
	    })

	    $('.sub-menu a').on("click", function(event) {
	        event.stopPropagation();
	    });
	}


/* ====================== Get Involved Nav ======================  */
    $('.anchor-nav .btn').on("click", function() {
        $('.anchor-nav .btn').removeClass('active');
        $(this).addClass('active');
    });


    if($('.anchor-nav').length) {
		let block = gsap.utils.toArray('.block:not(.anchor-nav)')
		block.forEach((item, index) => {
			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					once: false,
					start:"50% 50%",
					toggleActions:"restart none none reset",
					markers: false,
					onEnter: () => {
						var blockID = $(item).attr('ID');
						if(blockID) {
							$('.anchor-nav .btn').removeClass('active')
							$('.anchor-nav .btn[href="#' + blockID +'"]').addClass('active');
						}
					}
				}
			});
		});
	}

/* ====================== Semi Sticky Nav ======================  */
	var prevScrollpos = window.pageYOffset;
	var currentScrollPos = window.pageYOffset;

	if(currentScrollPos > 120) {
		$("header,.anchor-nav").addClass("headerscrolled");
	}

	document.addEventListener('scroll', function (event) {
		var currentScrollPos = window.pageYOffset;

		if ((prevScrollpos > currentScrollPos)) {
			$('header,.anchor-nav').addClass('show');
		} else if((prevScrollpos < currentScrollPos)){
			$('header,.anchor-nav').removeClass('show');
		}
		else {
			$('header,.anchor-nav').removeClass('show');
		}

		if(currentScrollPos > 120) {
			$("header,.anchor-nav").addClass("headerscrolled");
		} else {
			$("header,.anchor-nav").removeClass("headerscrolled show");
		}

		prevScrollpos = currentScrollPos;
	}, true);

/* ====================== Latest Posts Carousel ======================  */
	var numberOfSlides = 3.2;

	if(windowWidth < 768) {
		numberOfSlides = 1.2
	}

	const latest_posts_carousel = new Swiper('.news-carousel.swiper', {
       	slidesPerView: numberOfSlides,
       	speed: 600,
       	loop: false,
       	watchSlidesProgress: true,
       	spaceBetween: 25,
       	navigation: {
			nextEl: ".latest-news-carousel .swiper-button-next",
			prevEl: ".latest-news-carousel .swiper-button-prev",
		},
	});

/* ====================== Related Posts Carousel ======================  */
	var numberOfSlides = 1.2;

	if(windowWidth < 768) {
		const latest_posts_carousel = new Swiper('.interested-in .posts.swiper', {
	       	slidesPerView: numberOfSlides,
	       	speed: 600,
	       	loop: false,
	       	watchSlidesProgress: true,
	       	spaceBetween: 25,
		});
	}

/* ====================== Events Carousel ======================  */
	if(windowWidth < 1024) {
		var numberOfSlides = 2.2;

		if(windowWidth < 768) {
			numberOfSlides = 1.2
		}

		const team_members = new Swiper('.events-container.swiper', {
	       	slidesPerView: numberOfSlides,
	       	speed: 600,
	       	loop: false,
	       	watchSlidesProgress: true,
	       	spaceBetween: 20,
	       	navigation: {
				nextEl: ".upcoming-activities .swiper-button-next",
				prevEl: ".upcoming-activities .swiper-button-prev",
			},
		});
	}

/* ====================== Splitlines ======================  */
	const cta = new SplitType('.btn span, .filter span, header .main-nav > li:not(.static-btn) span', { types: 'chars' })
	cta.chars

	const headingMask = new SplitType('h1:not(.no-anim),h2:not(.no-anim),h3:not(.no-anim),h4:not(.no-anim)',
	{ types: 'lines,words' })
	headingMask.lines

/* ====================== Team Modal ======================  */
    const teamCard = $('.team-member');
    const teamModal = document.getElementById('team-modal');
    const teamModalClose = document.getElementById('team-modal-close');

    if(teamModal) {
    	$('.team-member').on("click", function() {
	    	teamModal.showModal();
	    	$('.inner-modal-content').hide();
	    	var teamMember = $(this).attr('team-member-related');

	    	$('.inner-modal-content[team-member-related=' + teamMember + ']').show();
	    	$('body').toggleClass('noscroll');
	    })

	    window.addEventListener('click', (event) => {
	      if (event.target === teamModal) {
	          teamModal.close();
	          $('body').removeClass('noscroll');
	      }
	    });

	    teamModalClose.addEventListener('click', () => {
	      teamModal.close();
	      $('body').removeClass('noscroll');
	    });
	}

/* ====================== Accordion ======================  */
	$('.accordion .row-title').on('click', function () {
		if($(this).hasClass('active')) {
			$(this).removeClass('active');
			$(this).next('.row-content').slideUp();
			$(this).next('.row-content').removeClass('active');
		} else {
			$('.accordion .row-title').removeClass('active');
			$('.row-content').slideUp();
			$('.row-content').removeClass('active');

			$(this).addClass('active');
			$(this).next('.row-content').slideDown();
			$(this).next('.row-content').addClass('active');
		}
    });

/* ====================== GSAP ANIMATIONS ======================  */
	if(windowWidth >= 1024) {
		let panels = gsap.utils.toArray(".panel")
		panels.forEach((panel, i) => {
		  ScrollTrigger.create({
		    trigger: panel,
		    start: "top top",
		    pin: i === panels.length -1 ? false : true,
		    end:"bottom top",
		    markers: false,
		    pinSpacing: false
		  });
		});
	}

	ScrollTrigger.matchMedia({
		'(min-width:1024px)':function(){
			let parallax = gsap.utils.toArray('.accent-image:not(.no-anim)');
			var val = -100;

			parallax.forEach((item, index) => {
				if(index == 1) {
					val = -50;
				}
				var trigger = $(item).parent();
				let tl = gsap.timeline({
				scrollTrigger: {
					scrub: 1,
					trigger: trigger[0],
					start:"-500 70%",
					toggleActions:"restart none none reset",
					end: "1000 70%",
					markers: false
				}
				});
				tl.to(item, {
					y: val,
					ease: 'none'
				});
			});
		}
	});

	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 90%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});

	let imageAnimate = gsap.utils.toArray('.image-container')
	imageAnimate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 70%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});

	let headingMaskAnim = gsap.utils.toArray('h1:not(.no-anim):not(.homepage-title), h2:not(.no-anim), h3:not(.no-anim)');
	headingMaskAnim.forEach((item, index) => {
		item.style.opacity = 1;

		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start: 'top 95%',
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
		tl.to(item.querySelectorAll(".word"), {
			y: 0,
			duration:3,
			ease: "power4.out",
			opacity: 1,
			duration: .4,
		});
	});

});
